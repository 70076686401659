<template>
  <div v-frag>
    <v-container fluid>
      <div class='absolute d-flex justify-center align-center pb-16' x-large>
        <h1 class='font-weight-black grey--text text--darken-1 mb-16'>
          Unauthorized Access
        </h1>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style>
.absolute {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.charity-name-section {
  display: flex;
  align-items: center;
}
.charity-name-section p {
  margin: 1rem;
}
.description-block {
  padding-left: 0;
}
.charity-name {
  word-wrap: break-word;
}
.contactBlock {
  display: flex;
  border-top: 1px solid #eee;
}
</style>
